import React, { Component } from 'react';
import Button from './Button';

class TalkToUsButton extends Component {
  // eslint-disable-next-line class-methods-use-this
  openChat() {
    // eslint-disable-next-line no-undef
    $crisp.push(['do', 'chat:open']);
  }

  render() {
    const { size, className } = this.props;
    return (
      <Button className={className} size={size} onClick={this.openChat}>
        Talk to us now
      </Button>
    );
  }
}

export default TalkToUsButton;
