import React from 'react';
import TalkToUsButton from '../components/TalkToUsButton';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

export default () => (
  <Layout>
    <section className="pt-20 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            We make <span style={{ color: '#4199e1' }}>App</span> to{' '}
            <span style={{ color: '#4199e1' }}>Wow</span> you and your users
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            We are a team of capable people who are passionate and enthusiastic about building great
            software whether they are web, mobile or service applications.
          </p>

          <p className="text-xl lg:text-2xl mt-6 font-light">
            In the past 2 years, we have helped entrepreneurs build thriving businesses with
            innovative and maintainable solution.
          </p>

          <p className="text-xl lg:text-2xl mt-6 font-light">
            We are so obsessed about cutting-edge technology that we can guarantee the tech stack we
            build for you will be modern and long-lasting so that you can have peace of mind that
            your app will run smoothly and successfully.
          </p>
          <p className="mt-8 md:mt-12">
            <TalkToUsButton size="lg" />
          </p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <h3
      id="services"
      className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none text-center pt-10"
    >
      Our services
    </h3>
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Web development</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We are experienced in making both static websites to represent your business and web
            applications to meet your needs. We always use latest web development technologies and
            practices to ensure the modernness of your website.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Mobile App development</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Mobile app is undoutedly how you become successful in business these days. We are
            comfortable building both Hybrid and native platforms. We will sit down and discuss with
            you and depending on what you need, we will always choose the best solution that fits
            your time and budget.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Backend service development</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Whether you want a brand new backend service or have us help maintain and improve your
            existing solution, we have got you covered. We also offer full deployment solution where
            we rely on top cloud providers such as AWS or Google Cloud that our engineers have a lot
            of experience with.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <section id="technologies" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Our Prefered Technologies</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Web</p>
              <p className="mt-4">React.js, Vue.js, Node.js</p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Mobile</p>
              <p className="mt-4">Swift, Kotlin, Flutter and React Native</p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Backend</p>
              <p className="mt-4">
                Node.js, Golang, Spring boot, PostgreSQL, MySQL, MongoDB, ElasticSearch, Kubernetes,
                AWS, Google Cloud
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="3" secondaryText="Projects" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="100%" secondaryText="Success rate" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="100%" secondaryText="Satisfaction rate" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">
        Looking for an agency to help build your IT success?
      </h3>
      <p className="mt-8 text-xl font-light">
        Look no further, we are the best team consisting of top engineers from leading tech
        companies in Thailand with vast experience of software development and delivery. What are
        you waiting for? Talk to us today to find out how we can help build your successful
        business.
      </p>
      <p className="mt-8">
        <TalkToUsButton size="xl" />
      </p>
    </section>
  </Layout>
);
