import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Helmet } from 'react-helmet';
import LogoIcon from '../../svg/LogoIcon';
import TalkToUsButton from '../TalkToUsButton';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <Helmet>
      <script>{`window.$crisp=[];window.CRISP_WEBSITE_ID="6183167b-8af2-4dbc-97ee-60d1bbfb999c";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}</script>
    </Helmet>
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <LogoIcon />
        </div>
        AppWow
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#services">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#technologies">
          Technologies
        </AnchorLink>
        <AnchorLink className="px-4" href="#stats">
          Stats
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <TalkToUsButton className="text-sm" />
      </div>
    </div>
  </header>
);

export default Header;
